import { useRef, useState } from 'react'
import type { ReactPortal } from 'react'
import { createPortal } from 'react-dom'

import { useIsomorphicEffect } from '~/hooks/useIsomorphicEffect'

export type PortalProps = {
  children: React.ReactNode
  target?: HTMLElement
}

export const Portal = ({ children, target }: PortalProps): ReactPortal | null => {
  const [mounted, setMounted] = useState(false)
  const ref = useRef<HTMLElement>()

  useIsomorphicEffect(() => {
    setMounted(true)

    ref.current = !target ? document.createElement('div') : target

    if (!target) {
      document.body.appendChild(ref.current)
    }

    return () => {
      !target && ref.current && document.body.removeChild(ref.current)
    }
  }, [target])

  if (!mounted || !ref.current) {
    return null
  }

  return createPortal(<div>{children}</div>, ref.current)
}
