import { X } from 'lucide-react'
import { classNames } from '~/utils/class-names'

export type NotificationProps = {
  children: React.ReactNode
  className?: string
  disallowClose?: boolean
  icon?: React.ReactNode
  loading?: boolean
  onClose?: () => void
  title?: React.ReactNode
  variant?: keyof typeof variants
}

const variants = {
  info: {
    icon: 'text-blue-500',
    loading: 'border-blue-500 border-r-blue-300',
    border: 'bg-blue-500',
  },
  success: {
    icon: 'text-green-500',
    loading: 'border-green-500 border-r-green-300',
    border: 'bg-green-500',
  },
  warn: {
    icon: 'text-yellow-500',
    loading: 'border-yellow-500 border-r-yellow-300',
    border: 'bg-yellow-500',
  },
  danger: {
    icon: 'text-red-500',
    loading: 'border-red-500 border-r-red-300',
    border: 'bg-red-500',
  },
}

export const Notification = ({
  children,
  className,
  disallowClose = false,
  icon,
  loading,
  onClose,
  title,
  variant = 'info',
}: NotificationProps) => {
  const withIcon = icon || loading
  const variantClasses = variants[variant]

  return (
    <div
      className={classNames(
        'pointer-events-auto w-full flex-shrink-0 overflow-hidden rounded-lg border border-border-primary bg-card shadow-2xl sm:max-w-sm',
        className,
      )}
    >
      <div className="p-4">
        <div className="flex items-center">
          {withIcon ? (
            <div className="mr-3 flex-shrink-0 self-center">
              {icon && !loading && (
                <div
                  className={classNames(
                    variantClasses.icon,
                    'flex h-7 w-7 items-center justify-center',
                  )}
                >
                  {icon}
                </div>
              )}
              {loading && (
                <div
                  className={classNames(
                    variantClasses.loading,
                    'h-7 w-7 animate-spin rounded-full border-4',
                  )}
                  data-testid="loading-icon"
                />
              )}
            </div>
          ) : (
            <div
              className={classNames(
                variantClasses.border,
                '-my-2 -ml-2 mr-2 w-1.5 self-stretch rounded',
              )}
            />
          )}
          <div className="w-0 flex-1 pt-0.5">
            {title && (
              <p className="mb-1 text-sm font-medium text-gray-900 dark:text-gray-100">{title}</p>
            )}
            <p className="text-sm text-gray-500 dark:text-gray-300">{children}</p>
          </div>
          {!disallowClose && (
            <div className="ml-4 flex flex-shrink-0 self-center">
              <button
                className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-300 focus:ring-offset-2 dark:bg-zinc-800 dark:text-gray-200"
                type="button"
                onClick={onClose}
              >
                <span className="sr-only">Close</span>
                <X />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
