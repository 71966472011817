import { createContext, useCallback, useContext, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Notification } from './Notification'
import { Portal } from '~/components/Portal'
import { classNames } from '~/utils/class-names'

export type NotificationItem = {
  id: string
  autoClose?: number | false
  disallowClose?: boolean
  icon?: React.ReactNode
  loading?: boolean
  message: React.ReactNode
  onClose?: (props: NotificationItem) => void
  onOpen?: (props: NotificationItem) => void
  title?: React.ReactNode
  variant?: 'info' | 'success' | 'warn' | 'danger'
}

export type NotificationProviderProps = {
  children: React.ReactNode | React.ReactElement
  position?: keyof typeof positions
  autoClose?: number | false
  limit?: number
}

export const NotificationContext = createContext<{
  showNotification: (options: Omit<NotificationItem, 'id'>) => void
}>({
  showNotification: () => {},
})

export const useNotification = () => useContext(NotificationContext)

const positions = {
  'top-left': 'items-start flex-col',
  'top-right': 'items-end flex-col',
  'top-center': 'items-center flex-col',
  'bottom-left': 'items-start flex-col-reverse space-y-reverse',
  'bottom-right': 'items-end flex-col-reverse space-y-reverse',
  'bottom-center': 'items-center flex-col-reverse space-y-reverse',
}

const autoCloseQueue: Record<string, ReturnType<typeof setTimeout>> = {}

export const NotificationProvider = ({
  children,
  position = 'top-center',
  autoClose = 5000,
  limit = 5,
}: NotificationProviderProps) => {
  const [notifications, setNotifications] = useState<NotificationItem[]>([])

  const showNotification = useCallback(
    (options: Omit<NotificationItem, 'id'>) => {
      const id = uuidv4()
      const autoCloseTime = options.autoClose ?? autoClose
      const props = { id, ...options }

      if (autoCloseTime !== false) {
        autoCloseQueue[id] = setTimeout(() => {
          close(id)
          options.onClose?.(props)
        }, autoCloseTime)
      }

      setNotifications((currentNotifications) => [...currentNotifications, props])
      options.onOpen?.(props)
    },
    [autoClose],
  )

  const close = (id: string) => {
    setNotifications((currentNotifications) =>
      currentNotifications.filter((notification) => notification.id !== id),
    )

    if (autoCloseQueue[id]) {
      clearTimeout(autoCloseQueue[id])
      delete autoCloseQueue[id]
    }
  }
  const contextValue = useMemo(() => ({ showNotification }), [showNotification])

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}

      <Portal>
        <div
          aria-live="assertive"
          className={classNames(
            'pointer-events-none fixed inset-0 z-40 flex content-end space-y-4 px-4 py-6 sm:p-6',
            positions[position],
          )}
        >
          {notifications.slice(0, limit).map((notification) => (
            <Notification
              disallowClose={notification.disallowClose}
              icon={notification.icon}
              key={notification.id}
              loading={notification.loading}
              title={notification.title}
              variant={notification.variant}
              onClose={() => {
                close(notification.id)
                notification.onClose?.(notification)
              }}
            >
              {notification.message}
            </Notification>
          ))}
        </div>
      </Portal>
    </NotificationContext.Provider>
  )
}
